import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Hauskauf = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="6 Tipps, die dir beim Hauskauf helfen werden" showCalc={false} />
            <Article>
                <p>
                    Dein Wunsch nach einem eigenen Haus ist gro&szlig;. Doch bevor du dich dazu entschlie&szlig;t, ihn
                    zu erf&uuml;llen, solltest du deine Beweggr&uuml;nde daf&uuml;r gut pr&uuml;fen. Die Gr&uuml;nde
                    f&uuml;r einen Hauskauf sind bei den meisten Menschen &auml;hnlich: Die meisten wollen darin wohnen,
                    manche wollen das Haus vermieten. Zu welcher Gruppe du auch geh&ouml;rst, du solltest einen guten
                    Plan haben. Hier sind einige Dinge, die du wissen solltest, bevor du dein erstes Haus kaufst.
                </p>
                <hr />

                <h2>Tipp 1: Rechne mit versteckten Kosten</h2>
                <p>
                    Deine monatlichen Zahlungen umfassen nicht nur die Kreditzahlungen, sondern auch Nebenkosten wie die
                    Grundsteuer und Haushaltsversicherung. Je nach H&ouml;he deiner Anzahlung musst du
                    m&ouml;glicherweise eine private Versicherung abschlie&szlig;en. Auch beim Kauf eines Hauses fallen
                    viele Kosten an. Diese k&ouml;nnen zwischen 2 % und 3,5 % des Kaufpreises betragen und umfassen z.
                    B. Anwalts- und Eigentumsgeb&uuml;hren.
                </p>
                <hr />

                <h2>Tipp 2: Es gibt viele Kreditarten</h2>
                <p>
                    Deine finanzielle Situation und deine Kreditw&uuml;rdigkeit bestimmen, welche Kreditart f&uuml;r
                    dich in Frage kommt. Die Kreditgeber pr&uuml;fen deine Gehaltsabrechnungen, Steuererkl&auml;rungen
                    und deine Kreditw&uuml;rdigkeit. Bessere Konditionen erh&auml;ltst du beispielsweise, wenn du seit
                    mehreren Jahren im selben Unternehmen besch&auml;ftigt bist. Der Zinssatz, f&uuml;r den du dich
                    qualifizierst, h&auml;ngt ganz von deiner Kreditw&uuml;rdigkeit ab. Bevor du eine Hypothek
                    beantragst, solltest du kostenlose Kopien deiner Kreditausk&uuml;nfte erhalten und diese
                    gr&uuml;ndlich auf Fehler &uuml;berpr&uuml;fen. Sobald dein Kreditgeber alle erforderlichen
                    Informationen hat, solltest du einen unverbindlichen Kostenvoranschlag erhalten, in dem die
                    Einzelheiten deines Kredits aufgef&uuml;hrt sind, damit du genau wei&szlig;t, was beim Hauskauf auf
                    dich zukommt.
                </p>
                <hr />

                <h2>Tipp 3: Beim Hauskauf ist Planung alles</h2>
                <p>
                    Wenn dein derzeitiger Beruf oder deine Beziehung dich in den n&auml;chsten f&uuml;nf Jahren in eine
                    andere Stadt, ein anderes Bundesland oder ein anderes Land f&uuml;hren k&ouml;nnten, solltest du
                    besonders sorgf&auml;ltig &uuml;ber den Kauf eines Hauses nachdenken. Grunds&auml;tzlich kannst du
                    ein Haus nat&uuml;rlich jederzeit wieder verkaufen, aber der Markt f&uuml;r Hauskauf- und verkauf
                    kann sich auch &auml;ndern. Diese Tatsache sollte dich keinesfalls vom Hauskauf abhalten, aber du
                    musst mit Marktschwankungen rechnen und sie in deiner langfristigen Planung ber&uuml;cksichtigen.
                    Das kommt auch bei Kreditgebern gut an!
                </p>
                <hr />

                <h2>Tipp 4: Du kannst dich unabh&auml;ngig beraten lassen</h2>
                <p>
                    Es ist wichtig, dass du Immobilienmakler*innen danach ausw&auml;hlst, wie gut du mit ihnen
                    kommunizieren kannst und dass sie in deinem Namen arbeiten, sobald du ein Haus gefunden hast, das du
                    kaufen m&ouml;chtest. Mit unserer Hilfe kannst du dich &uuml;ber die Provisionsstrukturen des
                    Maklers oder der Maklerin informieren und dar&uuml;ber, wie gut er oder sie sich in der Gegend
                    auskennt, in der du ein Haus kaufen m&ouml;chtest. Habe keine Angst, Fragen zu stellen (z. B. wie
                    lange sie schon im Immobiliengesch&auml;ft t&auml;tig sind) und versuche, ein Gef&uuml;hl daf&uuml;r
                    zu bekommen, wie leicht sie zu erreichen sind. Du wirst viel Zeit mit deine*r Immobilienmakler*in
                    verbringen, also nimm dir auch die Zeit, um ihn oder sie sorgf&auml;ltig auszuw&auml;hlen.
                </p>
                <hr />

                <h2>Tipp 5: Hilfe bei der Vertragsunterzeichnung ist wichtig</h2>
                <p>
                    Bei der Vertragsunterzeichnung treffen sich dein*e Makler*in, deine Kreditgeber und dein
                    Rechtsanwalt oder deine Rechtsanw&auml;ltin, um den gro&szlig;en Stapel Papierkram mit dir gemeinsam
                    durchzugehen. Du wirst durch jede Seite gef&uuml;hrt, damit du verstehst, was du da unterschreibst.
                    W&auml;hrend des Vertragsabschlusses geht ihr also gemeinsam den gesamten Papierkram durch, bis
                    alles best&auml;tigt und unterschrieben ist. Am Ende erh&auml;ltst du deine Kopien der Unterlagen
                    und die Schl&uuml;ssel zu deinem Haus. Danach kannst du die Steuervorteile und die g&uuml;nstigen
                    Investitionsmerkmale von Wohneigentum genie&szlig;en!
                </p>
                <hr />

                <h2>Tipp 6: Online-Kreditrechner k&ouml;nnen dir helfen</h2>
                <p>
                    Ermittle zuallererst anhand deines Einkommens, deiner Ausgaben, Ersparnisse, m&ouml;glicher Schulden
                    und deines Verm&ouml;gens, wie viel du dir &uuml;berhaupt leisten kannst. Wenn du Hilfe dabei
                    brauchst, z&ouml;gere nicht mit einem Finanzberater zu sprechen. &Uuml;ber unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    kannst du dir bereits einen &Uuml;berblick verschaffen.
                </p>
                <hr />

                <p>Dein n&auml;chster Schritt zum Hauskauf</p>
                <p>
                    Wenn du dich schon l&auml;nger f&uuml;r das Thema Hauskauf interessiert, dann tritt am besten noch
                    heute mit unsren Beratern in Kontakt. Unsere Beratung ist kostenlos und wir begleiten dich vom
                    Anfang bis zum Ende auf deinem Weg zu deiner eigenen Immobilie!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt beraten lassen!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"hauskauf"}></BreadcrumbList>
            <ArticleStructuredData page={"hauskauf"} heading={"6 Tipps, die dir beim Hauskauf helfen werden"} />
        </Layout>
    );
};

export default Hauskauf;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.hauskauf", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
